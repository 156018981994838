<template>
    <CRow :xs="{ cols: 1, gutter: 4 }">
        <ConditionModal :job-titles="options" :checked-titles="positionWithoutCondition" :show="showConditionModal"
                        :item="selectedCondition" :condition-name="conditionName" @close="handleCancelCondition"
                        @save="(formData) => handleSaveCondition(formData, 'document_approvals')"
        />
        <CCol class="col-12 col-xl-10 col-xxl-10">
            <CNInput v-model="form.name" :error="validationErrors.name" :invalid="!!validationErrors.name"
                     label='Please name contract approval workflow' @blur="validateField('name')" required />
        </CCol>
        <CCol>
            <CNListItem number="1"
                        text="Who are the people in your organization that will negotiate the redlines of the contract packages?"/>
        </CCol>
        <CCol v-for="(item, key) in form.document_approvals" :key="key">
            <JobTitleRow :ref="'DocApprovalRow_' + key" :validate="validateIndexes.includes(key)" :options="options"
                         :index="key" :ignore="checked" :form="item" :disabled="item.disabled"
                         @delete="() => handleRemoveDocumentApprovals(key)"/>
        </CCol>

        <CFormText v-if="validateCheckedApprovals" class="text-error mt-3">
            At least one person is required to approve the contract. Please use checkboxes to the right of job titles.
        </CFormText>

        <CCol class="p-0">
            <CDivider/>
        </CCol>

        <CCol>
            <CNListItem number="2"
                        text="Who is person in your organization that will add his signature on the final contract?"/>
        </CCol>

        <CCol>
            <JobTitleRow :ref="'SignatureApprovalRow_0'" :form="form.signature_approvals[0]" :index="0"
                         :options="options" disabled checked validate/>
        </CCol>

        <CCol style="height: 200px"></CCol>
    </CRow>
</template>

<script>
import JobTitleRow from './JobTitleRow'
import {DEF_ITEM} from '../../approval/constants'
import formMixin from '../../approval/formMixin'

export default {
    name: 'Form',
    components: {JobTitleRow},
    mixins: [formMixin],
    data() {
        return {
            validateIndexes: [0],
        }
    },
    computed: {
        checked() {
            return this.form.document_approvals.map((el) => el.position_id)
        },
        positionWithoutCondition() {
            return this.form.document_approvals
                .filter((el) => el.position_id && el.has_condition)
                .map((obj) => obj.position_id)
        },
        conditionName() {
            return this.form.document_approvals.map((el) => el.condition_name)
        },
        validateCheckedApprovals() {
            return (
                !this.form.document_approvals
                    .map((el) => el.mandatory)
                    .includes(true) && this.form.document_approvals.length > 1
            )
        },
    },
    watch: {
        'form.document_approvals': {
            handler: function (val) {
                if (val.every((el) => !!el.position_id) && this.form.document_approvals.length < this.options.length)
                    this.form.document_approvals.push(this.$deepClone(DEF_ITEM))
            },
            deep: true,
        },
    },
    methods: {
        clearErrorsRef() {
            this.validateIndexes.forEach((el) => {
                this.$refs['DocApprovalRow_' + el][0].errors = {}
                this.$refs['SignatureApprovalRow_' + el].errors = {}
            })

            this.validationErrors = {}
        },
        deepValidate() {
            let res = []
            this.validateIndexes.forEach((el) => {
                res.push(this.validateRef('DocApprovalRow_', el, 'document_approvals'))
                res.push(this.validateRef('SignatureApprovalRow_', el, 'signature_approvals'))
            })
            const mainValidate = this.validator.validateAll(this.form)
            this.validationErrors = mainValidate.validationErrors
            let mainHasErrors = this.validateCheckedApprovals ? this.validateCheckedApprovals : mainValidate.hasErrors
            res.push(mainHasErrors)

            return res.some((el) => el === true)
        },
        validateRef(refName, key, formKey) {
            let el = Array.isArray(this.$refs[refName + key])
                ? this.$refs[refName + key][0]
                : this.$refs[refName + key]
            let res = el.validator.validateAll(this.form[formKey][key])
            el.errors = res.validationErrors
            return res.hasErrors
        },
        handleRemoveDocumentApprovals(index) {
            this.form.document_approvals.splice(index, 1)
        },
    },
}
</script>

<style scoped>
.additionBtn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #1c262f;
}
</style>
