<template>
  <CRow>
    <CCol class="col-7 col-md-8 col-lg-7 col-xl-8 col-xxl-10">
      <CNSelect v-model="form.position_id" :options="availableOpt" :error="errors.position_id" :invalid="!!errors.position_id" label="Select Job Title" @change="validateField('position_id')" />
    </CCol>
    <CCol class="col-auto mt-3">
      <CNCheckbox v-model="form.mandatory" :disabled="!form.position_id || disabled" />
    </CCol>
    <CCol class="col-auto text-center" style="margin-top: 14px">
      <CIcon name="cilX" :class="[form.position_id && !disabled ? 'text-error' : 'text-disable', 'pointer']" @click="handleDelete" />
    </CCol>
  </CRow>
</template>

<script>
import CNSelect from '@/components/ui/CNSelect/CNSelect'
import CNCheckbox from '@/components/ui/CNCheckbox/CNCheckbox'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'

export default {
  name: 'JobTitleRow',
  components: {
    CNSelect,
    CNCheckbox,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    ignore: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({ position_id: '', mandatory: false }),
    },
    // errorsS: {
    //   type: Object,
    //   default: {},
    // },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete'],
  data() {
    return {
      validator: {},
      errors: {},
    }
  },
  computed: {
    availableOpt() {
      return this.options.filter((el) => {
        if (!this.ignore.includes(el.value) || el.value === this.form.position_id)
          return el
      })
    },
  },
  mounted() {
    if (this.validate) {
      this.validator = validator({ position_id: [rules.required, rules.number] })
    }
  },
  methods: {
    handleDelete() {
      if (this.form.position_id) this.$emit('delete')
    },
    validateField(field) {
      this.$nextTick(() => this.errors[field] = this.validator.validate(field, this.form[field]))
    },
  },
}
</script>

<style scoped>

</style>
